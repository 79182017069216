import { UserInfo } from "@/libs/zustand/useAuthStore";
import { SignInForm } from "@/shared/components/CommonModal/customer/ModalSignIn";
import { RegisterValue } from "@/shared/layouts/default-layout/types";
import { CookiesKeys } from "@/shared/types/cookies";
import { getCookie } from "@/shared/helpers/cookie";
import { BusinessUserAxios } from "@/libs/axios/crud.axios";

export type OtpType =
  | "registration"
  | "forget-password"
  | "get-private"
  | "update-private"
  | "delete-account"
  | "change-password";

export class BusinessUserRepository {
  // generate OTP for customer
  async getOTP({ type, object }: { type: "registration" | "forget-password"; object: string }) {
    return BusinessUserAxios.postReq(`/otp/${type}/send`, {
      object: object,
      object_type: "email",
    });
  }

  // generate OTP for account
  async getAuthorizedOTP(typeOtp: OtpType) {
    return await BusinessUserAxios.postReq(`/user/otp/${typeOtp}/send`, {
      otp_type: "email",
    });
  }

  async registerUser(values: RegisterValue) {
    return BusinessUserAxios.postReq("/user/register", values);
  }

  async login(values: SignInForm) {
    return BusinessUserAxios.postReq("/user/login", {
      username: values.username,
      password: values.password,
    });
  }

  async getOneUserById() {
    return await BusinessUserAxios.getReq("/user/get-info");
  }

  async getPrivateInfo(password: string) {
    return await BusinessUserAxios.postReq("/user/private/get", {
      password,
    });
  }

  async updateUserInfo(values: any) {
    delete values.email;
    delete values.phone_number;
    delete values.id;
    delete values.updater_id;
    delete values.user_id;
    return await BusinessUserAxios.putReq<Pick<UserInfo, "user_profile_info">>(
      "/user/update",
      values,
    );
  }

  async verifyOTP({ email, otp, typeOtp }: { email: string; otp: string; typeOtp: OtpType }) {
    return await BusinessUserAxios.postReq(`/otp/${typeOtp}/verify`, {
      object: email,
      otp,
      otp_type: "email",
    });
  }

  async verifyAuthorizedOTP({
    otp,
    typeOtp,
    email,
  }: {
    otp: string;
    typeOtp: OtpType;
    email: string;
  }) {
    return await BusinessUserAxios.postReq(`/user/otp/${typeOtp}/verify`, {
      object: email,
      otp,
      otp_type: "email",
    });
  }

  async changePassword(old_password: string, new_password: string, otp: string) {
    return await BusinessUserAxios.postReq("/user/change-password", {
      old_password,
      new_password,
      otp,
    });
  }

  async updatePrivateInfo(
    otp?: string,
    otp_type?: "email" | "phone",
    email?: string,
    phone_number?: string,
    username?: string,
  ) {
    return await BusinessUserAxios.putReq("/user/private/update", {
      otp: otp || undefined,
      otp_type: otp_type || undefined,
      email: email || undefined,
      phone_number: phone_number || undefined,
      username: username || undefined,
    });
  }

  async updateEmailOtp(otp: string) {
    return await BusinessUserAxios.getReq("/user/update-email", {
      params: {
        otp,
      },
    });
  }

  async deleteUser(otp: string) {
    return await BusinessUserAxios.deleteReq("/user/delete", {
      data: {
        otp,
        otp_type: "email",
      },
    });
  }

  async verifyUser({
    username_email,
    phone_number,
  }: {
    username_email?: string;
    phone_number?: string;
  }) {
    return await BusinessUserAxios.getReq("/user/verify-user", {
      params: {
        username_email,
        phone_number,
      },
    });
  }

  async forgetPassword({ username_email }: { username_email: string }) {
    return await BusinessUserAxios.postReq("/user/forgot-password", {
      object: username_email,
      otp_resend: true,
    });
  }

  async resetPassword({
    object,
    otp,
    new_password,
  }: {
    object: string;
    otp: string;
    new_password: string;
  }) {
    return await BusinessUserAxios.postReq("/user/reset-password", {
      object,
      otp,
      new_password,
    });
  }

  async verifyOldEmailToken(token: string) {
    return await BusinessUserAxios.postReq(`/user/update-email/verify/${token}`, {});
  }

  async updateEmail(otp: string) {
    return await BusinessUserAxios.postReq("/user/update-email", {
      otp,
    });
  }

  async getToken() {
    const cookie = getCookie(CookiesKeys.user_info);

    return await BusinessUserAxios.getReq("/user/auth/refresh", {
      headers: {
        "x-refresh-token": cookie?.refresh_token,
        Authorization: "",
      },
    })
      .then((res) => res)
      .catch((err) => err);
  }
}

export const BusinessUserService = new BusinessUserRepository();
