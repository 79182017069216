import { KeyboardEvent } from "react";

export const scrollIntoViewWithOffset = (selector: HTMLElement | null, offset: number) => {
  window.scrollTo({
    behavior: "smooth",
    top: selector!.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  });
};

export const handleSubmitEnter = (
  event: KeyboardEvent<HTMLDivElement>,
  submitForm: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
) => {
  if (event.key === "Enter") {
    submitForm(); // Trigger form submission
  }
};

export const isVietnamesePhoneNumber = (phone: string) => {
  const prefix = phone.slice(0, 2);
  const length = phone.length;

  if (isNaN(Number(phone))) return false;

  if (
    prefix === "03" ||
    prefix === "05" ||
    prefix === "07" ||
    prefix === "08" ||
    prefix === "09" ||
    prefix === "02"
  ) {
    return length === 10 || length === 11;
  }

  if (prefix === "84") {
    return length === 11;
  }

  return false;
};

export const isValidNationalId = (id: string) => {
  if (isNaN(Number(id))) return false;

  return id.length === 12 || id.length === 9;
};

export const filterUndefined = <T>(arr: (T | undefined)[]): T[] => {
  return arr.filter((item) => item !== undefined) as T[];
};
