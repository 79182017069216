import { useAuthStore, UserInfo } from "@/libs/zustand/useAuthStore";
import { useCustomerAuthStore } from "@/libs/zustand/useCustomerAuthStore";
import { useTimeExpiredOtp } from "@/libs/zustand/useTimeExpiredOtp";
import { schemaGeneralProfile, schemaPrivateProfile } from "@/modules/customer/schemas";
import { TABS } from "@/modules/profile/constants";
import ProfileConfirmDeleteAccountModal, {
  DeleteAccountValues,
} from "@/modules/profile/ProfileConfirmDeleteAccountModal";
import ProfileConfirmPasswordModal from "@/modules/profile/ProfileConfirmPasswordModal";
import ProfileConfirmUsernamePhoneModal from "@/modules/profile/ProfileConfirmUsernamePhoneModal";
import ProfileFormContent from "@/modules/profile/ProfileFormContent";
import { ProfileSection } from "@/modules/profile/types";
import { CustomerService } from "@/services/customer.service";
import { REGEX_DATE } from "@/shared/constants/regex";
import { removeCookie } from "@/shared/helpers/cookie";
import ErrorHelper from "@/shared/helpers/error-helper";
import PrefixHelper from "@/shared/helpers/prefix-helper";
import { useDevice } from "@/shared/hooks/useDevices";
import useLoading from "@/shared/hooks/useLoading";
import useModal, { useModalConfirmCancel } from "@/shared/hooks/useModal";
import { setToastError, setToastSuccess } from "@/shared/hooks/useToast";
import { CookiesKeys } from "@/shared/types/cookies";
import { Button, Tabs, usePopover } from "@jfds1/jf-design-system";
import clsx from "clsx";
import { format } from "date-fns";
import { Formik, FormikProps } from "formik";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function Profile() {
  const [valuesForm, setValuesFrom] = useState<UserInfo>({} as UserInfo);
  const { userData, setUserData } = useAuthStore();
  const { isMobile } = useDevice();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setcurrentTab] = useState<ProfileSection>(ProfileSection.general);
  const { setTimeExpired } = useTimeExpiredOtp();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const { onOpen: onOpenModalConfirmPassword, ...popoverModalConfirmPassword } = usePopover();

  const edit = searchParams.get("edit");

  const { onOpen: onOpenModalConfirmUsernamePhone, ...popoverPropsModalConfirmUsernamePhone } =
    usePopover();

  const { onOpen: onOpenModalConfirmDelete, ...popoverModalConfirmDelete } = usePopover();

  const { openModal, onClose } = useModal();
  const { openModalConfirmCancel } = useModalConfirmCancel();

  const { toggleOpenModalChangePassword, toggleOpenModalConfirmEmail } = useCustomerAuthStore();

  const deleteAccount = () => {
    openModal({
      actions: [
        {
          onClick: () => {
            onClose();
          },
          children: "Huỷ",
          variant: "secondary",
          color: "primary",
        },
        {
          onClick: () => {
            onClose();
            onOpenModalConfirmDelete({} as any);
          },
          children: "Xác nhận",
          variant: "negative",
        },
      ],
      bodyContent: "Bạn chắc chắn muốn xóa tài khoản?",
      variant: "delete",
      title: "Xóa tài khoản",
    });
  };

  const handleSelectTab = (tab: string) => {
    const searchParams = new URLSearchParams(window.location.search);

    const updatedValuesForm: UserInfo = {
      ...valuesForm,
      user_profile_info: {
        ...valuesForm.user_profile_info,
        birthday: valuesForm.user_profile_info?.birthday
          ? `${new Date(valuesForm.user_profile_info.birthday).toISOString()}`
          : null,
      },
    };
    // console.log(updatedValuesForm, userData);

    if (
      tab === ProfileSection.security &&
      edit === "true" &&
      !isEqual(updatedValuesForm, {
        ...userData,
        user_profile_info: {
          ...(userData?.user_profile_info ?? {}),
          birthday: userData?.user_profile_info?.birthday
            ? `${new Date(userData.user_profile_info.birthday).toISOString()}`
            : null,
          email: PrefixHelper.getDisplayKeyFromPrefixString(userData?.user_profile_info.email),
          phone_number: PrefixHelper.getDisplayKeyFromPrefixString(
            userData?.user_profile_info.phone_number,
          ),
        },
        username: PrefixHelper.getDisplayKeyFromPrefixString(userData?.username),
      })
    ) {
      openModalConfirmCancel({
        handCancel: () => {
          onOpenModalConfirmPassword({} as any);
          onClose();
        },
      });
      return;
    } else if (tab === ProfileSection.security) {
      onOpenModalConfirmPassword({} as any);
      return;
    }
    setcurrentTab(tab as ProfileSection);
    searchParams.set("tabId", tab);
    setSearchParams(searchParams);
  };

  const handleDeleteAccount = async (values: DeleteAccountValues) => {
    await CustomerService.deleteUser(values.otp)
      .then(() => {
        removeCookie(CookiesKeys.user_info);
        removeCookie(CookiesKeys.user_role);
        setUserData(null as any);
        setToastSuccess({
          title: "Xóa tài khoản thành công",
        });
        window.location.href = "/";
      })
      .catch((error) => setToastError({ title: "Error: " + ErrorHelper.getErrorMessage(error) }));
  };

  const handleSubmitProfile = async (values: UserInfo) => {
    try {
      startLoading();
      if (
        values?.user_profile_info.email !==
        PrefixHelper.getDisplayKeyFromPrefixString(userData?.user_profile_info.email)
      ) {
        await CustomerService.updatePrivateInfo(
          undefined,
          "email",
          values?.user_profile_info.email,
        );
        toggleOpenModalConfirmEmail();
      } else if (
        values?.user_profile_info.phone_number !==
          PrefixHelper.getDisplayKeyFromPrefixString(userData?.user_profile_info.phone_number) ||
        values?.username !== PrefixHelper.getDisplayKeyFromPrefixString(userData?.username)
      ) {
        const result = await CustomerService.getAuthorizedOTP("update-private");
        onOpenModalConfirmUsernamePhone({} as any);
        setTimeExpired(result.data.expired_at, "update-private");
      } else if (currentTab !== ProfileSection.security) {
        values.user_profile_info.birthday = !values.user_profile_info.birthday
          ? null
          : new Date(values.user_profile_info.birthday).toISOString();
        const result = await CustomerService.updateUserInfo(values.user_profile_info as any);
        setUserData({
          ...userData,
          user_profile_info: result.data.user_profile_info,
        } as any);
        searchParams.delete("edit");
        searchParams.delete("tabId");
        setSearchParams(searchParams);
        setToastSuccess({
          title: "Cập nhật thông tin thành công",
        });
      }
    } catch (error) {
      setToastError({
        title: "Cập nhật thông tin thất bại: " + ErrorHelper.getErrorMessage(error),
      });
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (searchParams.get("tabId")) {
      setcurrentTab(searchParams.get("tabId") as ProfileSection);
    }
  }, [searchParams]);

  return (
    <>
      <div className='container min-h-[calc(100vh-88px)] sm:min-h-[calc(80vh)]'>
        <div className='mx-auto flex max-w-7xl flex-col gap-6 py-6 pt-0 sm:p-4'>
          <div className='sticky top-[80px] z-30 flex items-center justify-between bg-background-grey pt-6 sm:top-[64px] sm:pt-4'>
            <Tabs
              onSelect={handleSelectTab}
              tabs={TABS}
              selected={currentTab}
              className={clsx(
                "remove-padding-tab-item h-12 gap-8 px-0 py-0 sm:w-full",
                isLoading && "pointer-events-none",
              )}
              tabClassName='h-full text-primary sm:w-full sm:flex sm:justify-center'
            />
            <div className='block sm:hidden'>
              {searchParams.get("tabId") === "security" && edit && (
                <Button
                  variant='secondary'
                  onClick={toggleOpenModalChangePassword}
                  className={"h-10 bg-transparent px-4 py-2"}
                >
                  Đổi mật khẩu
                </Button>
              )}
              {edit && currentTab === "security" && (
                <Button
                  variant='secondary'
                  color='error'
                  onClick={deleteAccount}
                  className={"h-10 bg-transparent px-4 py-2"}
                >
                  Xóa tài khoản
                </Button>
              )}
            </div>
          </div>
          {searchParams.get("tabId") === "security" && edit === "true" && (
            <Button
              variant='secondary'
              onClick={toggleOpenModalChangePassword}
              className={"hidden h-10 bg-transparent px-4 py-2 sm:block"}
            >
              Đổi mật khẩu
            </Button>
          )}
          {userData && (
            <Formik
              enableReinitialize
              innerRef={(f: FormikProps<UserInfo>) => {
                setValuesFrom(f?.values as UserInfo);
              }}
              initialValues={{
                ...userData,
                user_profile_info: {
                  ...(userData.user_profile_info ?? {}),
                  birthday: userData.user_profile_info.birthday
                    ? format(new Date(userData.user_profile_info.birthday), "yyyy-MM-dd")
                    : "",
                  email: PrefixHelper.getDisplayKeyFromPrefixString(
                    userData.user_profile_info.email,
                  ),
                  phone_number: PrefixHelper.getDisplayKeyFromPrefixString(
                    userData.user_profile_info.phone_number,
                  ),
                },
                username: PrefixHelper.getDisplayKeyFromPrefixString(userData.username),
              }}
              validationSchema={
                currentTab === "general" ? schemaGeneralProfile : schemaPrivateProfile
              }
              onSubmit={handleSubmitProfile}
              validate={(values) => {
                const error: any = {};
                if (values.user_profile_info.birthday) {
                  if (!REGEX_DATE.test(values.user_profile_info.birthday)) {
                    error.user_profile_info = { birthday: "Ngày sinh không hợp lệ" };
                  }
                }
                return error;
              }}
            >
              {({ handleSubmit, setValues }) => {
                return (
                  <div className='flex flex-col gap-6'>
                    <div className='flex flex-col items-center justify-center gap-6 rounded-2xl border bg-white p-6 sm:px-4'>
                      <ProfileFormContent selectedTab={currentTab} />
                    </div>
                    {edit === "true" && isMobile && currentTab === "security" && (
                      <Button
                        variant='secondary'
                        color='error'
                        onClick={deleteAccount}
                        className={"h-10 bg-transparent px-4 py-2"}
                      >
                        Xóa tài khoản
                      </Button>
                    )}
                    <div className='flex justify-end pb-12 pt-6 sm:pb-6 sm:pt-0'>
                      {edit === "true" && (
                        <Button
                          onClick={() => {
                            if (edit === "true") {
                              searchParams.delete("edit");
                              setSearchParams(searchParams);
                            } else {
                              searchParams.set("tabId", "general");
                              setSearchParams(searchParams);
                            }
                            setValues(userData!);
                          }}
                          className='h-12 w-[112px] sm:w-full'
                          variant='secondary'
                          disabled={isLoading}
                        >
                          Hủy
                        </Button>
                      )}
                      <Button
                        type='submit'
                        className='h-12 w-[112px] sm:w-full'
                        variant='primary'
                        disabled={isLoading}
                        onClick={() => {
                          if (edit === "true") {
                            handleSubmit();
                          } else {
                            searchParams.set("edit", "true");
                            setSearchParams(searchParams);
                          }
                        }}
                      >
                        {edit === "true" ? "Lưu" : "Chỉnh sửa"}
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          )}
        </div>
      </div>

      <ProfileConfirmUsernamePhoneModal
        {...popoverPropsModalConfirmUsernamePhone}
        valuesForm={valuesForm}
      />

      <ProfileConfirmPasswordModal {...popoverModalConfirmPassword} setcurrentTab={setcurrentTab} />

      <Formik
        initialValues={{
          username: "",
          password: "",
          otp: "",
          checked: false,
        }}
        onSubmit={handleDeleteAccount}
      >
        <ProfileConfirmDeleteAccountModal {...popoverModalConfirmDelete} />
      </Formik>
    </>
  );
}

export default Profile;
