import { differenceInSeconds } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useState } from "react";
import TimeHelper from "../helpers/time-helper";
import useInterval from "../hooks/useInterval";

type CountdownTimerProps = {
  targetDate: Date | undefined;
  // createdAt: Date;
  onComplete?: () => void;
  // otpType: keyof TimeExpiredOtpType;
};

const CountdownTimer = (props: CountdownTimerProps) => {
  const { targetDate, onComplete } = props;
  const _tagetDate = targetDate ?? new Date();
  const [timeRemaining, setTimeRemaining] = useState<number>(0);

  useInterval(() => {
    console.clear();
    const nowVietnam = utcToZonedTime(new Date(), "Asia/Saigon");
    const targetDateVietnam = utcToZonedTime(new Date(_tagetDate), "Asia/Saigon");

    const difference = differenceInSeconds(targetDateVietnam, nowVietnam);

    if (difference <= 0) {
      onComplete && targetDate && onComplete();
      setTimeRemaining(0);
    } else {
      setTimeRemaining(difference);
    }
  }, 1000);

  if (!targetDate) return <span className='animate-pulse'>&nbsp;Đang chờ...</span>;

  return (
    <span className='font-semibold text-black'>
      &nbsp;
      {TimeHelper.zeroPadFormatDuration({
        minutes: Math.floor(timeRemaining / 60),
        seconds: timeRemaining % 60,
      })}
    </span>
  );
};

export default CountdownTimer;
