import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import Router from "./routers/Router";
import Loading from "./shared/components/Loading/Loading";
import Toast from "./shared/components/ToastContainer";
import "./styles/main.scss";
import Modal from "./shared/components/ModalContainer";
import numeral from "numeral";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://17233895a223c0d879a1f16c8f97737d@o4505719040114688.ingest.sentry.io/4506229488418816",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// custom new locale for numeral as vietnamese
numeral.register("locale", "vi", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "tr",
    billion: "tỷ",
    trillion: "ngàn tỷ",
  },
  ordinal: function (number) {
    return number === 1 ? "1" : "0";
  },
  currency: {
    symbol: "đ",
  },
});

numeral.locale("vi");

function App() {
  return (
    <Suspense
      fallback={
        <div className='h-screen w-full'>
          <Loading />
        </div>
      }
    >
      <Router />
      <Toast />
      <Modal />
    </Suspense>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
