import { useAuthStore } from "@/libs/zustand/useAuthStore";
import { useTimeExpiredOtp } from "@/libs/zustand/useTimeExpiredOtp";
import { CustomerService } from "@/services/customer.service";
import { handleSubmitEnter } from "@/shared/helpers/funcs";
import { setToastError } from "@/shared/hooks/useToast";
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverProps,
  Step,
  StepItem,
  StepStatus,
  TextField,
  Typography,
} from "@jfds1/jf-design-system";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import ProfileStepOtp from "./ProfileStepOTP";

export type DeleteAccountValues = {
  username: "";
  password: "";
  otp: "";
  checked: false;
};

const StepAccount = () => {
  const { handleChange, values, errors } = useFormikContext<{
    username: string;
    password: string;
  }>();
  return (
    <div className='flex flex-col gap-4'>
      <TextField
        className='w-full'
        name='username'
        onChange={handleChange}
        value={values.username}
        isError={!!errors.username}
        helperText={errors.username}
        placeholder='Placeholder'
        label='Tên đăng nhập'
        required
        startAdornment={<Icon color='default' iconName='profile-user' />}
      />
      <TextField
        className='w-full'
        name='password'
        onChange={handleChange}
        value={values.password}
        isError={!!errors.password}
        helperText={errors.password}
        placeholder='Placeholder'
        label='Mật khẩu'
        required
        type='password'
        startAdornment={<Icon color='default' iconName='login' />}
      />
    </div>
  );
};

const StepVerifyDeleteAccount = () => {
  const { values, handleChange } = useFormikContext<DeleteAccountValues>();
  return (
    <div className='flex flex-col gap-8'>
      <Typography variant='body' className='paragraph-clean text-center text-text-500 body'>
        Tài khoản sẽ bị xóa. Bạn có chắc chắn xác nhận xóa tài khoản không?
      </Typography>

      <div className='flex items-center gap-2 md:items-start'>
        <Checkbox name='checked' checked={values.checked} onChange={handleChange} />
        <p className='font-normal'>
          Tôi đã đọc và đồng ý với
          <span className='whitespace-normal font-semibold text-primary'>
            &nbsp; Điều Khoản & Điều kiện
          </span>
        </p>
      </div>
    </div>
  );
};

const data: StepItem[] = [
  { id: "step1", title: "Tài khoản" },
  {
    id: "step2",
    title: "OTP",
  },
  {
    id: "step3",
    title: "Xác nhận",
  },
];

const initialStepStatus: StepStatus = {
  step1: "default",
  step2: "default",
  step3: "default",
};

const ProfileConfirmDeleteAccountForm = ({
  selected,
  handleSubmit,
}: {
  selected: number;
  handleSubmit: () => void;
}) => {
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      handleSubmitEnter(event, handleSubmit);
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleSubmit]);

  const content = useMemo(() => {
    return {
      0: <StepAccount />,
      1: <ProfileStepOtp typeOtp='delete-account' />,
      2: <StepVerifyDeleteAccount />,
    };
  }, []);
  return <>{React.cloneElement(content[selected as keyof typeof content], {})}</>;
};

const ProfileConfirmDeleteAccountModal = (props: PopoverProps) => {
  const [selected, setSelected] = useState<number>(0);
  const [stepStatus, setStepStatus] = useState<StepStatus>(initialStepStatus);
  const { userData } = useAuthStore();
  const { handleSubmit: submitForm, values } = useFormikContext<DeleteAccountValues>();
  const { setTimeExpired } = useTimeExpiredOtp();

  const handleSubmit = async () => {
    switch (selected) {
      case 0:
        await CustomerService.login({
          password: values.password,
          username: values.username,
          remember_me: false,
        })
          .then(async () => {
            await CustomerService.getAuthorizedOTP("delete-account").then((res: any) => {
              setSelected(1);
              setStepStatus({ ...stepStatus, step1: "finish" });
              setTimeExpired(res.data.expired_at, "delete-account");
            });
          })
          .catch(() => {
            setToastError({
              title: "Tên đăng nhập hoặc mật khẩu không đúng",
            });
          });
        break;
      case 1:
        await CustomerService.verifyAuthorizedOTP({
          email: userData?.user_profile_info.email || "",
          otp: values.otp,
          typeOtp: "delete-account",
        })
          .then(() => {
            setSelected(2);
            setStepStatus({ ...stepStatus, step2: "finish" });
          })
          .catch(() => {
            setToastError({
              title: "Mã OTP không đúng",
            });
          });
        break;
      case 2:
        submitForm();
        break;
    }
  };

  const handleCancel = () => {
    props.onClose();
    setSelected(0);
    setStepStatus(initialStepStatus);
  };

  return (
    <Modal
      {...props}
      onClose={() => {
        props.onClose();
        setSelected(0);
        setStepStatus(initialStepStatus);
      }}
      className='w-[417px]'
    >
      <ModalHeader
        title='Xóa tài khoản'
        onClose={() => {
          props.onClose();
          setSelected(0);
          setStepStatus(initialStepStatus);
        }}
        className='flex justify-between p-6 text-18 font-semibold'
      />
      <ModalBody>
        <Step
          className='pointer-events-none mx-auto mb-12 mt-6 w-[77%] px-6'
          direction='horizontal'
          onSelect={setSelected}
          steps={data}
          type='number'
          current={selected}
          status={stepStatus}
        />
        <ProfileConfirmDeleteAccountForm selected={selected} handleSubmit={handleSubmit} />
      </ModalBody>
      <ModalFooter className='flex flex-col gap-4'>
        <Button
          fullWidth
          onClick={handleSubmit}
          className='h-[48px]'
          disabled={selected === 2 && !values.checked}
        >
          Xác nhận
        </Button>
        <Button
          fullWidth
          type='button'
          className='!m-0 h-[48px]'
          variant='secondary'
          onClick={handleCancel}
        >
          Huỷ
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileConfirmDeleteAccountModal;
