import { ButtonProps, isFunction, ModalConfirmHeaderProps } from "@jfds1/jf-design-system";
import clsx from "clsx";
import { ReactNode } from "react";
import { create } from "zustand";

type ModalConfirmProps = ModalConfirmHeaderProps & {
  bodyContent: ReactNode;
  actions: Array<ButtonProps>;
};

interface ModalStore {
  open: boolean;
  modalProps: ModalConfirmProps;
  setModalProps: (_props: ModalConfirmProps) => void;
  onClose: () => void;
  onOpen: () => void;
}

export const useModalStore = create<ModalStore>((set) => ({
  open: false,
  modalProps: { variant: "delete", title: "", bodyContent: "", actions: [] },
  setModalProps: (modalProps) => set({ modalProps }),
  onClose: () => set({ open: false }),
  onOpen: () => set({ open: true }),
}));

export default function useModal() {
  const { onOpen, onClose, setModalProps } = useModalStore();
  function openModal(modalProps: ModalConfirmProps) {
    onOpen();
    setModalProps({
      ...modalProps,
      onClose: () => {
        onClose();
        if (isFunction(modalProps.onClose)) {
          modalProps.onClose();
        }
      },
      className: clsx("sm:max-w-[calc(100vw-32px)]", modalProps.className),
    });
  }
  return {
    openModal,
    onClose,
  };
}

export function setConfirmModalDelete({
  handleDelete,
  handleCancel,
  disabledButton,
  cancelButtonText,
  deleteButtonText,
  ...modalProps
}: {
  handleDelete?: () => Promise<void>;
  handleCancel?: () => void;
  disabledButton?: boolean;
  deleteButtonText?: string;
  cancelButtonText?: string;
} & Partial<ModalConfirmProps>) {
  const { setModalProps, onClose, onOpen } = useModalStore.getState();

  setModalProps({
    actions: [
      {
        children: cancelButtonText ?? "Cancel",
        variant: "secondary",
        onClick: async () => {
          handleCancel?.();
          onClose();
        },
        className: "h-12 w-[112px]",
        disabled: disabledButton,
      },
      {
        variant: "negative",
        children: deleteButtonText ?? "Delete",
        onClick: async () => {
          await handleDelete?.();
          onClose();
        },
        className: "h-12 w-[112px]",
        disabled: disabledButton,
      },
    ],
    ...modalProps,
    bodyContent: modalProps.bodyContent ?? "Are you sure you want to delete this value?",
    title: modalProps.title ?? "Delete",
    variant: modalProps.variant ?? "delete",
    onClose: onClose,
  });
  onOpen();
}

export function useModalConfirmCancel() {
  const { onClose, setModalProps, onOpen } = useModalStore();

  function openModalConfirmCancel({ handCancel }: { handCancel: () => void }) {
    onOpen();
    setModalProps({
      variant: "warning",
      title: "Are you sure you want to cancel?",
      bodyContent: "All information will be discarded. Are you sure to cancel?",
      actions: [
        {
          variant: "secondary",
          children: "Reject",
          onClick: onClose,
        },
        {
          variant: "primary",
          children: "Confirm",
          onClick: handCancel ?? onClose,
        },
      ],
    });
  }
  return {
    openModalConfirmCancel,
    onClose,
  };
}
