export const toastStyle = {
  info: "text-[#1EC5FA]",
  success: "text-[#33B76B]",
  warning: "text-[#F5BC33]",
  error: "text-[#EC3C36]",
};

export const iconNames = {
  info: "information",
  success: "success",
  warning: "warning",
  error: "failed-remove",
};
