import { useState } from "react";

const useLoading = (defaultValue?: boolean) => {
  const [isLoading, setLoading] = useState<boolean>(defaultValue ?? false);

  const [error, setError] = useState("");

  const startLoading = () => {
    setLoading(true);
    setError("");
  };

  const stopLoading = () => {
    setLoading(false);
  };

  return { isLoading, startLoading, stopLoading, setError, error };
};

export default useLoading;
