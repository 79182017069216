import { useAuthStore } from "@/libs/zustand/useAuthStore";
import { useTimeExpiredOtp } from "@/libs/zustand/useTimeExpiredOtp";
import { CustomerService } from "@/services/customer.service";
import { ChangePassValues } from "@/shared/components/CommonModal/customer/ModalChangePass/types";
import CountdownTimer from "@/shared/components/CountDownTimer";
import PrefixHelper from "@/shared/helpers/prefix-helper";
import { OTPInput } from "@jfds1/jf-design-system";
import clsx from "clsx";
import { useFormikContext } from "formik";
import { useState } from "react";

const ProfileStepOtp = ({ typeOtp }: { typeOtp: "update-private" | "delete-account" }) => {
  const { values, handleChange, errors } = useFormikContext<ChangePassValues>();
  const { userData } = useAuthStore();
  const { setTimeExpired, timeExpired } = useTimeExpiredOtp();
  const [isResend, setIsResend] = useState<boolean>(false);

  const handleResendOTP = async () => {
    await CustomerService.getAuthorizedOTP(typeOtp).then((res: any) => {
      setIsResend(false);
      setTimeExpired(res.data.expired_at, typeOtp);
    });
  };

  return (
    <>
      <p className='mb-8 whitespace-normal text-center font-normal text-grey-500'>
        Mã OTP đã được gửi đến
        <span className='text-main'>{`"${PrefixHelper.getDisplayKeyFromPrefixString(
          userData?.user_profile_info.email,
        )}"`}</span>
        <br /> Vui lòng kiểm tra và điền.
        <span className='font-semibold text-black'>
          <CountdownTimer
            targetDate={new Date(timeExpired[typeOtp] || 0)}
            onComplete={() => setIsResend(true)}
          />
        </span>
      </p>
      <OTPInput
        name='otp'
        value={values.otp}
        onChange={handleChange}
        isError={!!errors.otp}
        helperText={errors.otp}
        className='max-w-[369px]'
      />
      <p className='mt-4 text-center font-normal text-grey-500'>
        Không nhận được OTP ?
        <span
          className={clsx(
            "ml-4 font-semibold",
            isResend ? "cursor-pointer text-primary" : "pointer-events-none text-text-500",
          )}
          onClick={handleResendOTP}
        >
          Gửi lại
        </span>
      </p>
    </>
  );
};

export default ProfileStepOtp;
