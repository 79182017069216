import { UserInfo } from "@/libs/zustand/useAuthStore";
import { SignInForm } from "@/shared/components/CommonModal/customer/ModalSignIn";
import { RegisterValue } from "@/shared/layouts/default-layout/types";
import { CookiesKeys } from "@/shared/types/cookies";
import { getCookie } from "@/shared/helpers/cookie";
import { CustomerAxios } from "@/libs/axios/crud.axios";

export type OtpType =
  | "registration"
  | "forget-password"
  | "get-private"
  | "update-private"
  | "delete-account"
  | "change-password";

export class CustomerRepository {
  // generate OTP for customer
  async getOTP({ type, object }: { type: "registration" | "forget-password"; object: string }) {
    return CustomerAxios.postReq(`/otp/${type}/send`, {
      object: object,
      object_type: "email",
    });
  }

  // generate OTP for account
  async getAuthorizedOTP(typeOtp: OtpType) {
    return await CustomerAxios.postReq(`/user/otp/${typeOtp}/send`, {
      otp_type: "email",
    });
  }

  async registerUser(values: RegisterValue) {
    return CustomerAxios.postReq("/user/register", values);
  }

  async login(values: SignInForm) {
    return CustomerAxios.postReq("/user/login", {
      username: values.username,
      password: values.password,
    });
  }

  async getOneUserById() {
    return await CustomerAxios.getReq("/user/get-info");
  }

  async getPrivateInfo(password: string) {
    return await CustomerAxios.postReq("/user/private/get", {
      password,
    });
  }

  async updateUserInfo(values: any) {
    delete values.email;
    delete values.phone_number;
    delete values.id;
    delete values.updater_id;
    delete values.user_id;
    return await CustomerAxios.putReq<Pick<UserInfo, "user_profile_info">>("/user/update", values);
  }

  async verifyOTP({ email, otp, typeOtp }: { email: string; otp: string; typeOtp: OtpType }) {
    return await CustomerAxios.postReq(`/otp/${typeOtp}/verify`, {
      object: email,
      otp,
      otp_type: "email",
    });
  }

  async verifyAuthorizedOTP({
    otp,
    typeOtp,
    email,
  }: {
    otp: string;
    typeOtp: OtpType;
    email: string;
  }) {
    return await CustomerAxios.postReq(`/user/otp/${typeOtp}/verify`, {
      object: email,
      otp,
      otp_type: "email",
    });
  }

  // user = {
  //   register: {
  //     verify: async (data: {
  //       username: string;
  //       password: string;
  //       object_system_key: string;
  //       otp: string;
  //       otp_receiver: string;
  //       otp_type: string;
  //       first_name: string;
  //       last_name: string;
  //       email: string;
  //       phone_number: string;
  //       gender: string;
  //       birthday: string;
  //       avatar: string;
  //       is_active: true;
  //     }) => {
  //       return await CustomerAxios.postReq(`/user/register/verify`, data);
  //     },
  //   },
  // };

  async changePassword(old_password: string, new_password: string, otp: string) {
    return await CustomerAxios.postReq("/user/change-password", {
      old_password,
      new_password,
      otp,
    });
  }

  async updatePrivateInfo(
    otp?: string,
    otp_type?: "email" | "phone",
    email?: string,
    phone_number?: string,
    username?: string,
  ) {
    return await CustomerAxios.putReq(
      "/user/private/update",
      {
        otp: otp || undefined,
        otp_type: otp_type || undefined,
        email: email || undefined,
        phone_number: phone_number || undefined,
        username: username || undefined,
      },
      {
        headers: {
          "x-client-platform": "customer",
        },
      },
    );
  }

  async updateEmailOtp(otp: string) {
    return await CustomerAxios.getReq("/user/update-email", {
      params: {
        otp,
      },
    });
  }

  async deleteUser(otp: string) {
    return await CustomerAxios.deleteReq("/user/delete", {
      data: {
        otp,
        otp_type: "email",
      },
    });
  }

  async verifyUser({
    username_email,
    phone_number,
  }: {
    username_email?: string;
    phone_number?: string;
  }) {
    return await CustomerAxios.getReq("/user/verify-user", {
      params: {
        username_email,
        phone_number,
      },
    });
  }

  async forgetPassword({ username_email }: { username_email: string }) {
    return await CustomerAxios.postReq("/user/forgot-password", {
      object: username_email,
      otp_resend: true,
    });
  }

  async resetPassword({
    object,
    otp,
    new_password,
  }: {
    object: string;
    otp: string;
    new_password: string;
  }) {
    return await CustomerAxios.postReq("/user/reset-password", {
      object,
      otp,
      new_password,
    });
  }

  async verifyOldEmailToken(token: string) {
    return await CustomerAxios.postReq(`/user/update-email/verify/${token}`, {});
  }

  async updateEmail(otp: string) {
    return await CustomerAxios.postReq("/user/update-email", {
      otp,
    });
  }

  async getToken() {
    const cookieElynetUserInfo = getCookie(CookiesKeys.user_info);
    return await CustomerAxios.getReq("/user/auth/refresh", {
      headers: {
        "x-refresh-token": cookieElynetUserInfo?.refresh_token,
        Authorization: "",
      },
    })
      .then((res) => res)
      .catch((err) => err);
  }
}

export const CustomerService = new CustomerRepository();
