import { Alert } from "@jfds1/jf-design-system";
import clsx from "clsx";
import { useCallback, useEffect } from "react";

import { useToastStore, type ToastOption } from "@/shared/hooks/useToast";

import { toastStyle } from "./constants";

export interface ToastProps extends ToastOption {
  id: string;
}

export default function Toast(props: ToastProps) {
  const {
    id,
    delay,
    title,
    onClose,
    children,
    className,
    iconClasses = "",
    iconName = "success",
    variant = "success",
    ...others
  } = props;

  const closeToast = useToastStore((state) => state.closeToast);

  const handleCloseToast = useCallback(() => {
    if (onClose) onClose();
    closeToast(id);
  }, [closeToast, id, onClose]);

  useEffect(() => {
    const timer = setTimeout(() => handleCloseToast(), delay);

    return () => clearTimeout(timer);
  }, [id, closeToast, delay, handleCloseToast]);

  const iconClassNames = clsx(iconClasses, toastStyle[variant]);

  return (
    <Alert
      title={title}
      iconName={iconName}
      onClose={handleCloseToast}
      className={`alert bg-white md:w-[calc(100vw-32px)] md:whitespace-normal ${className}`}
      iconClasses={`${iconClassNames}`}
      {...others}
    >
      {children}
    </Alert>
  );
}
