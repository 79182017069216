import { IconName } from "@jfds1/jf-design-system";

import { useToastStore } from "@/shared/hooks/useToast";

import { iconNames } from "./constants";
import Toast from "./Toast";

export default function ToastContainer() {
  const toastList = useToastStore((state) => state.toastStack);

  return (
    <div className='fixed bottom-10 right-10 z-[9999] flex h-auto w-auto flex-col gap-4 md:right-4 md:left-4'>
      {toastList.length > 0 &&
        toastList.map((toast) => {
          const { id, options } = toast;
          const variant = options?.variant ? options.variant : "success";
          const renderDefaultIcon: IconName = options?.iconName || (iconNames[variant] as IconName);
          const delay = options?.delay && options.delay > 0 ? options.delay : 5000;

          return (
            <Toast key={id} id={id} iconName={renderDefaultIcon} delay={delay} {...options}>
              {options?.children && options.children}
            </Toast>
          );
        })}
    </div>
  );
}
