export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const zeroPad = (num: number) => String(num).padStart(2, "0");

function getFirstLetters(str: string) {
  const firstLetters = str
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");

  return firstLetters;
}

export const shortNameAddress = (nameAddress: string) => {
  const arr = ["Thành phố", "Tỉnh", "Huyện", "Quận", "Thị xã", "Thị trấn", "Phường", "Xã"];

  if (arr.some((item) => nameAddress.includes(item))) {
    const index = arr.findIndex((item) => nameAddress.includes(item));
    const shortName = nameAddress.replace(
      arr[index],
      `${getFirstLetters(arr[index]).toUpperCase()}. `,
    );

    return shortName;
  }

  return nameAddress;
};

export const listWithCommas = (list: string[]) => {
  return list.join(", ");
};

export const parsePhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) return "";
  const cleanedPhoneNumber = phoneNumber.replace(/-/g, "");

  // Inserting a space before the last two digits
  const formattedPhoneNumber =
    cleanedPhoneNumber.slice(0, 2) + " " + cleanedPhoneNumber.slice(2, phoneNumber.length);

  return formattedPhoneNumber.startsWith("84") ? `+${formattedPhoneNumber}` : formattedPhoneNumber;
};
