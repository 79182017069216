import { useAuthStore } from "@/libs/zustand/useAuthStore";
import { CustomerService } from "@/services/customer.service";
import { handleSubmitEnter } from "@/shared/helpers/funcs";
import PrefixHelper from "@/shared/helpers/prefix-helper";
import useLoading from "@/shared/hooks/useLoading";
import { setToastError, setToastSuccess } from "@/shared/hooks/useToast";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverProps,
} from "@jfds1/jf-design-system";
import { Formik, useFormikContext } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ProfileStepOtp from "./ProfileStepOTP";

const ProfileConfirmUsernamePhoneModalForm = (props: PopoverProps & { isLoading: boolean }) => {
  const { handleSubmit, resetForm } = useFormikContext<{ otp: "" }>();

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      handleSubmitEnter(event, handleSubmit);
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <>
      <ModalHeader
        title='Xác nhận'
        onClose={() => {
          props.onClose();
          resetForm();
        }}
        className='flex justify-between p-6 text-18 font-semibold'
      />
      <ModalBody>
        <ProfileStepOtp typeOtp='update-private' />
      </ModalBody>
      <ModalFooter className='flex flex-col gap-4'>
        <Button
          fullWidth
          type='button'
          className='h-[48px]'
          disabled={props.isLoading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Xác nhận
        </Button>
        <Button
          fullWidth
          type='button'
          className='!m-0 h-[48px]'
          variant='secondary'
          onClick={() => {
            props.onClose();
            resetForm();
          }}
        >
          Huỷ
        </Button>
      </ModalFooter>
    </>
  );
};

function ProfileConfirmUsernamePhoneModal(props: PopoverProps & { valuesForm: any }) {
  const { valuesForm } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData, setUserData } = useAuthStore();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const handleUpdateUsernamePhone = async ({
    values,
    resetForm,
    phone_number,
    username,
  }: {
    values: any;
    resetForm: () => void;
    phone_number?: string;
    username?: string;
  }) => {
    startLoading();
    await CustomerService.updatePrivateInfo(values.otp, "email", undefined, phone_number, username)
      .then(() => {
        setUserData({
          ...userData,
          user_profile_info: {
            ...userData?.user_profile_info,
            phone_number: valuesForm.user_profile_info.phone_number,
            username: valuesForm.username,
          },
        } as any);
        searchParams.delete("edit");
        searchParams.set("tabId", "general");
        setSearchParams(searchParams);
        setToastSuccess({
          title: "Cập nhật thông tin thành công",
        });
        props.onClose();
        resetForm();
        stopLoading();
      })
      .catch(() => {
        setToastError({
          title: "OTP không hợp lệ",
        });
        stopLoading();
      });
  };

  return (
    <Formik
      initialValues={{ otp: "" }}
      onSubmit={async (values, { resetForm }) => {
        if (
          valuesForm.user_profile_info.phone_number !==
          PrefixHelper.getDisplayKeyFromPrefixString(userData?.user_profile_info.phone_number)
        ) {
          await handleUpdateUsernamePhone({
            values: values,
            resetForm: resetForm,
            phone_number: valuesForm.user_profile_info.phone_number,
          });
        } else {
          await handleUpdateUsernamePhone({
            values: values,
            resetForm: resetForm,
            username: valuesForm.username,
          });
        }
      }}
    >
      <Modal {...props} className='w-[417px]'>
        <ProfileConfirmUsernamePhoneModalForm {...props} isLoading={isLoading} />
      </Modal>
    </Formik>
  );
}

export default ProfileConfirmUsernamePhoneModal;
