import { IconName, uuid } from "@jfds1/jf-design-system";
import { ReactNode } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type ToastVariant = "info" | "success" | "warning" | "error";
export type ToastPosition =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

export type ToastOption = {
  max?: number;
  delay?: number;
  action?: ReactNode;
  className?: string;
  iconName?: IconName;
  children?: ReactNode;
  iconClasses?: string;
  onClose?: () => void;
  variant?: ToastVariant;
  position?: ToastPosition;
  title: string;
};

export interface Toast {
  id: string;
  options: ToastOption;
}

export type ToastStore = {
  toastStack: Toast[];
  enqueueToast: (opts: ToastOption) => void;
  closeToast: (id: string) => void;
};

export const useToastStore = create<ToastStore>()(
  immer((set) => ({
    toastStack: [],
    enqueueToast: (options: ToastOption) =>
      set((draftState) => {
        draftState.toastStack.push({ id: uuid(), options });
      }),
    closeToast: (id) =>
      set((draft) => {
        draft.toastStack = draft.toastStack.filter((item) => item.id !== id);
      }),
  })),
);

export default function useToast() {
  return useToastStore((store) => store.enqueueToast);
}

export function setToastError({ title, children }: { title: string; children?: ReactNode }) {
  return useToastStore.getState().enqueueToast({
    title,
    children,
    variant: "error",
  });
}

export function setToastSuccess({ title, children }: { title: string; children?: ReactNode }) {
  return useToastStore.getState().enqueueToast({
    title,
    children,
    variant: "success",
  });
}
