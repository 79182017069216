import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export enum UserRole {
  "customer" = "customer",
  "seller" = "seller",
}

type LocationDetailType = {
  id: string;
  creator_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  phone_number: string;
  object_id: string;
  type: string;
  is_default: false;
  object_type: string;
  street_address: string;
  extract_address: string;
  location_detail: {
    ward_commune: string;
    city_province: string;
    district_town: string;
  };
};

export interface UserProfileInfo {
  id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  email: string;
  phone_number: string;
  birthday: string | null;
  avatar: string;
  first_name: string;
  last_name: string;
  gender: "Male" | "Female";
  address_info: Array<LocationDetailType>;
}

export type UserInfo = {
  id: string;
  creator_id: string;
  created_at: string;
  updated_at: string;
  username: string;
  last_login: string;
  object_system_key: string;
  is_active: true;
  user_profile_info: UserProfileInfo;
  entity_term_taxonomy_ids: Array<string>;
};

type AuthStore = {
  userData: UserInfo | null;
  setUserData: (data: UserInfo | null) => void;
  userRole: UserRole | null;
  setUserRole: (data: UserRole | null) => void;
  completeRegister: boolean;
  setCompleteRegister: (value: boolean) => void;
};

export const useAuthStore = create<AuthStore>()(
  immer((set) => ({
    userData: null,
    setUserData: (data: UserInfo | null) => {
      set((state) => {
        state.userData = data;
      });
    },
    userRole: null,
    setUserRole: (data: UserRole | null) => {
      set((state) => {
        state.userRole = data;
      });
    },
    completeRegister: false,
    setCompleteRegister: (value: boolean) => {
      set((state) => {
        state.completeRegister = value;
      });
    },
  })),
);
