export const ELYNET_ACCESS_TOKEN = import.meta.env.VITE_ELYNET_ACCESS_TOKEN;

const BASE_URL = import.meta.env.VITE_BASE_URL;

// STANDARDIZE
export const API_URL_ENTITY = BASE_URL + "/jf-entity/api/v1";
export const API_URL_ITEM = BASE_URL + "/jf-item/api/v1";
export const API_URL_SKU = BASE_URL + "/jf-sku/api/v1";
export const API_URL_STOCK = BASE_URL + "/jf-sku/api/v1";
export const API_URL_CUSTOMER = BASE_URL + "/jf-customer/api/v1";
export const API_URL_BUSINESS_USER = BASE_URL + "/jf-business-user/api/v1";
export const API_URL_LOCATION = BASE_URL + "/jf-location/api/v1";
export const API_URL_ORDER = BASE_URL + "/jf-order/api/v1";
export const API_URL_PRODUCT_OLAP = BASE_URL + "/jf-product-olap/api/v1";
export const API_URL_ORDER_OLAP = BASE_URL + "/jf-order-olap/api/v1";
export const API_URL_PAYMENT = BASE_URL + "/jf-payment/api/v1";
export const API_URL_DELIVERY = BASE_URL + "/jf-delivery/api/v1";
export const API_URL_CART = BASE_URL + "/jf-cart/api/v1";
export const API_URL_ES_PRODUCT = BASE_URL + "/jf-es-product/api/v1";
export const API_URL_SAGA_ORCHESTRATION = BASE_URL + "/jf-saga-orchestration/api/v1";

// NON-STANDARDIZE
export const API_URL_ONLINE_PAYMENT = "https://payment.jcore.me/api/v1";
