import { addMinutes, differenceInDays, format, formatDuration } from "date-fns";
import { zeroPad } from "./parser";

const TimeHelper = {
  toDateString: (time: string | Date): string => {
    const date = new Date(time);
    return format(date, "MMM dd, yyyy");
  },
  /**
   * Format date time to readable string
   */
  toString(
    date: string | number | Date,
    options?: {
      noTime?: boolean;
      noDate?: boolean;
      isShort?: boolean;
    },
  ): string {
    const { noDate, noTime, isShort } = options || {};

    const _date = new Date(date);

    if (isShort) {
      if (noDate) {
        return format(_date, "HH:mm");
      }
      if (noTime) {
        return format(_date, "dd/MM");
      }
      return format(_date, "HH:mm '-' dd/MM");
    } else {
      if (noTime) {
        return format(_date, "dd 'tháng' MM");
      }
      if (noDate) {
        return format(_date, "HH 'giờ' mm 'phút");
      }
      return format(_date, "dd 'tháng' M 'lúc' HH:mm");
    }
  },

  /**
   * Get time string from number of seconds
   */
  convertNumberOfSecondsToString(
    numberOfSeconds: number,
    options?: {
      isShort: boolean;
    },
  ): string {
    const { isShort } = options || {};

    const dateFromSeconds = new Date(numberOfSeconds * 1000);

    // Time difference between client and GMT in minutes
    const timezoneOffsetInMinutes = dateFromSeconds.getTimezoneOffset();

    const intervalizedDate = addMinutes(dateFromSeconds, timezoneOffsetInMinutes);

    if (numberOfSeconds < 60) {
      if (isShort) {
        return format(intervalizedDate, "s'gi'");
      }
      return format(intervalizedDate, "s 'giây'");
    } else if (numberOfSeconds < 3600) {
      if (isShort) {
        return format(intervalizedDate, "m'p'");
      }
      return format(intervalizedDate, "m 'phút' s 'giây'");
    } else if (numberOfSeconds < 86400) {
      if (isShort) {
        return format(intervalizedDate, "h'g' m'p'");
      }
      return format(intervalizedDate, "h 'giờ' m 'phút'");
    } else {
      if (isShort) {
        return format(intervalizedDate, "d'ng' h'g'");
      }
      return format(intervalizedDate, "d 'ngày' h 'giờ'");
    }
  },

  /**
   * Calculate interval of @date to now
   */
  fromNow(
    date: string | number | Date,
    options?: {
      isShort: boolean;
    },
  ): string {
    const { isShort } = options || {};

    const time = (new Date(date).getTime() - new Date().getTime()) / 1000;

    if (time <= 120) {
      return "Vừa xong";
    }
    if (time < 3600) {
      return `${Math.floor(time / 60)} phút ` + (isShort ? "" : "trước");
    }
    if (time < 86400) {
      return `${Math.floor(time / 3600)} giờ ` + (isShort ? "" : "trước");
    }
    if (time >= 864000) {
      return `${Math.floor(time / 86400)} ngày ` + (isShort ? "" : "trước");
    }

    return isShort
      ? new Date(date).toLocaleDateString("vi")
      : `Vào ${new Date(date).toLocaleString()}`;
  },

  zeroPadFormatDuration(duration: Duration) {
    if (
      typeof duration.hours === "number" &&
      typeof duration.years === "number" &&
      typeof duration.months === "number" &&
      typeof duration.days === "number"
    ) {
      duration.hours += duration.years * 24 * 365;
      duration.hours += duration.months * 24 * 30;
      duration.hours += duration.days * 24;
    }

    return formatDuration(duration, {
      format: ["minutes", "seconds"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => zeroPad(count),
      },
    });
  },

  // format duration from now to endTime to locale vi string
  getRemainingTime(endTime: Date) {
    const now = new Date();

    let remainingTime = "";
    remainingTime += `${differenceInDays(endTime, now)} ngày `;

    return remainingTime;
  },

  // parseDurationToHours(years: number, months: number, weeks: number, days: number) {
  //   let currentDate = new Date(); // Start with the current date and time

  //   // Add the specified number of years, months, weeks, and days to the current date
  //   // currentDate = addYears(currentDate, years);
  //   // currentDate = addMonths(currentDate, months);
  //   // currentDate = addWeeks(currentDate, weeks);
  //   currentDate = addDays(currentDate, 1);

  //   // Calculate the difference in hours between the current date and the starting date
  //   const hours = differenceInHours(currentDate, new Date());

  //   return hours;
  // },
};

export default TimeHelper;
