import Cookies, { CookieAttributes } from "js-cookie";
import { CookiesKeys } from "@/shared/types/cookies";
export const getCookie = (name: CookiesKeys) => {
  const value = Cookies.get(name);
  return value ? JSON.parse(value) : null;
};
export const setCookie = <T>(name: CookiesKeys, value: T, options?: CookieAttributes) => {
  return Cookies.set(name, JSON.stringify(value), options);
};
export const removeCookie = (name: CookiesKeys) => {
  return Cookies.remove(name);
};
export function getCookieExpirationTime(cookieName: string) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${cookieName}=`)) {
      const cookieParts = cookie.split("=");
      // const cookieValue = decodeURIComponent(cookieParts[1]);
      const cookieOptions = cookieParts[2] ? cookieParts[2].split(";") : [];
      for (let j = 0; j < cookieOptions.length; j++) {
        const option = cookieOptions[j].trim();
        if (option.startsWith("expires=")) {
          const expiresValue = option.substring("expires=".length);
          return new Date(expiresValue).toUTCString();
        }
      }
    }
  }
  return null; // Cookie not found or expiration time not set
}
