import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type TimeExpiredOtpType = {
  register: Date | undefined;
  "forget-password": Date | undefined;
  "get-private": Date | undefined;
  "update-private": Date | undefined;
  "delete-account": Date | undefined;
  "change-password": Date | undefined;
  "change-email": Date | undefined;
};

interface TimeExpiredOtpStore {
  timeExpired: TimeExpiredOtpType;
  setTimeExpired: (time: Date | undefined, type: keyof TimeExpiredOtpType) => void;
  // timeCreated: TimeExpiredOtpType;
  // setTimeCreated: (time: Date | undefined, type: keyof TimeExpiredOtpType) => void;
}

export const useTimeExpiredOtp = create<TimeExpiredOtpStore>()(
  immer((set) => ({
    timeExpired: {
      register: undefined,
      "forget-password": undefined,
      "get-private": undefined,
      "update-private": undefined,
      "delete-account": undefined,
      "change-password": undefined,
      "change-email": undefined,
    },
    setTimeExpired: (time: Date | undefined, type: keyof TimeExpiredOtpType) => {
      set((state) => {
        state.timeExpired[type] = time;
      });
    },
    // timeCreated: {
    //   register: undefined,
    //   "forget-password": undefined,
    //   "get-private": undefined,
    //   "update-private": undefined,
    //   "delete-account": undefined,
    // },
    // setTimeCreated: (time: Date | undefined, type: keyof TimeExpiredOtpType) => {
    //   set((state) => {
    //     state.timeCreated[type] = time;
    //   });
    // },
  })),
);
