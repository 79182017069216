import { AxiosError } from "axios";
import capitalize from "lodash/capitalize";
import { isString } from "@jfds1/jf-design-system";

const ErrorHelper = {
  getErrorMessage: (e: AxiosError<{ error: string }> | any) => {
    let errorString = "";
    if (isString(e.message)) errorString = e.message;
    if (isString(e?.response?.data?.error)) errorString = e?.response?.data?.error;
    if (isString(e?.response?.data?.error?.detail)) errorString = e?.response?.data?.error?.detail;
    return capitalize(errorString);
  },
};

export default ErrorHelper;
