import { Button, Modal, ModalBody, ModalConfirmHeader, ModalFooter } from "@jfds1/jf-design-system";

import { useModalStore } from "@/shared/hooks/useModal";

const ModalContainer = () => {
  const { open, onClose, modalProps } = useModalStore();
  const { bodyContent, actions, className, ...restProps } = modalProps;
  return (
    <Modal open={open} onClose={onClose} className={className}>
      {restProps.title && <ModalConfirmHeader {...restProps} onClose={onClose} />}
      <ModalBody>{bodyContent}</ModalBody>
      {actions.length > 0 && (
        <ModalFooter>
          {actions.map((btn, index) => (
            <Button key={index} {...btn} className={`h-[48px] w-[112px] ${btn.className}`} />
          ))}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default ModalContainer;
