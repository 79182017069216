import TabsContents from "@/shared/components/TabsContents";
import { lazy, ReactNode } from "react";
import { ProfileSection } from "./types";

const ProfileGeneral = lazy(() => import("./ProfileGeneral"));
const ProfileSecurity = lazy(() => import("./ProfileSecurity"));

const TABS_CONTENTS: { tabId: ProfileSection; content: ReactNode }[] = [
  { tabId: ProfileSection.general, content: <ProfileGeneral /> },
  {
    tabId: ProfileSection.security,
    content: <ProfileSecurity />,
  },
];

function ProfileFormContent({ selectedTab }: { selectedTab: ProfileSection }) {
  return <TabsContents selectedTab={selectedTab} tabContents={TABS_CONTENTS} />;
}

export default ProfileFormContent;
