import Profile from "@/pages/profile/Profile";
import RetrictedAuth from "@/shared/layouts/RetrictedAuth";
import { lazy } from "react";
import { createBrowserRouter, RouteObject, RouterProvider } from "react-router-dom";
const ScrollToTop = lazy(() => import("@/shared/components/ScrollToTop/ScrollToTop"));
const DefaultLayout = lazy(() => import("@/shared/layouts/default-layout/DefaultLayout"));
const SellerLayout = lazy(() => import("../shared/layouts/seller-layout/SellerLayout"));
const CustomerOrderDetail = lazy(() => import("@/pages/customer/CustomerOrderDetail"));
const CustomerOrderList = lazy(() => import("@/pages/customer/CustomerOrderList"));
const InformationLayout = lazy(
  () => import("@/shared/layouts/information-layout/InformationLayout"),
);
const AboutUsPage = lazy(() => import("@/pages/informations/AboutUsPage"));
const ContactPage = lazy(() => import("@/pages/informations/ContactPage"));
const CustomerServicePage = lazy(() => import("@/pages/informations/CustomerServicePage"));
const VNPAYPage = lazy(() => import("@/pages/informations/VNPAY"));

// Customer
const CustomerCart = lazy(() => import("@/pages/customer/CustomerCart"));
const CustomerOrder = lazy(() => import("@/pages/customer/CustomerOrder"));
const CustomerOrderPaymentResult = lazy(
  () => import("@/pages/customer/CustomerOrderPaymentResult"),
);
const CustomerOrderSuccess = lazy(() => import("@/pages/customer/CustomerOrderSuccess"));
const CustomerProductDetail = lazy(() => import("@/pages/customer/CustomerProductDetail"));

// Home
const HomePage = lazy(() => import("@/pages/home/HomePage"));

// Products
// const ProductDetail = lazy(() => import("@/pages/product/ProductDetail"));

// Stores

const Search = lazy(() => import("@/pages/search/Search"));

// Error
const Page404 = lazy(() => import("@/pages/404"));

// Profile
// const Profile = lazy(() => import("@/pages/profile/Profile"));

// Verify Email
const VerifyEmail = lazy(() => import("@/pages/verify/VerifyEmail"));

export const routes: RouteObject[] = [
  {
    path: "/",
    errorElement: <Page404 />,
    element: (
      <ScrollToTop>
        <DefaultLayout />
      </ScrollToTop>
    ),
    children: [
      {
        index: true,
        element: <Search />,
      },
      {
        id: "customer-product-detail",
        path: "/product/:shareLinkId",
        element: <CustomerProductDetail />,
      },

      {
        path: "/cart",
        element: <CustomerCart />,
      },

      {
        path: "/orders",
        children: [
          {
            path: "success",
            element: <CustomerOrderSuccess />,
          },
          {
            path: ":idOrder/detail",
            element: <CustomerOrderDetail />,
          },
          {
            path: "create",
            element: <CustomerOrder />,
          },
          {
            index: true,
            element: (
              <RetrictedAuth>
                <CustomerOrderList />
              </RetrictedAuth>
            ),
          },
          {
            path: "payment-result",
            element: (
              <RetrictedAuth>
                <CustomerOrderPaymentResult />
              </RetrictedAuth>
            ),
          },
        ],
      },
      {
        path: "/profile",
        element: (
          <RetrictedAuth>
            <Profile />
          </RetrictedAuth>
        ),
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: "/seller",
    element: (
      <ScrollToTop>
        <SellerLayout />
      </ScrollToTop>
    ),
    errorElement: <Page404 />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      // Products
      {
        id: "product-detail",
        path: "products/:shareLinkId",
        element: <CustomerProductDetail />,
      },
      // Stores
      // {
      //   id: "stores",
      //   path: "stores",
      //   children: [
      //     {
      //       index: true,
      //       element: <StoresPage />,
      //     },
      //     {
      //       id: "store-detail",
      //       path: ":idStore",
      //       element: <StoreDetail />,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/informations",
    element: (
      <ScrollToTop>
        <InformationLayout />
      </ScrollToTop>
    ),
    errorElement: <Page404 />,
    children: [
      {
        path: "about-us",
        id: "about-us",
        element: <AboutUsPage />,
      },
      {
        path: "contact",
        id: "contact",
        element: <ContactPage />,
      },
      {
        path: "customer-service",
        id: "customer-service",
        element: <CustomerServicePage />,
      },
    ],
  },
  {
    path: "/informations",
    element: (
      <ScrollToTop>
        <DefaultLayout />
      </ScrollToTop>
    ),
    children: [
      {
        path: "vnpay",
        id: "vnpay",
        element: <VNPAYPage />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

const Router = (): React.ReactElement => {
  return <RouterProvider router={router} />;
};

export default Router;
