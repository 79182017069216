import clsx from "clsx";
import { ReactNode } from "react";

function TabsContents({
  tabContents,
  selectedTab,
}: {
  tabContents: { tabId: string; content: ReactNode }[];
  selectedTab: string;
}) {
  return (
    <>
      {tabContents.map((tabContent) => (
        <div
          key={tabContent.tabId}
          className={clsx(selectedTab === tabContent.tabId ? "block w-full" : "hidden")}
        >
          {tabContent.content}
        </div>
      ))}
    </>
  );
}

export default TabsContents;
