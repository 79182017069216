import { useAuthStore } from "@/libs/zustand/useAuthStore";
import { CustomerService } from "@/services/customer.service";
import { handleSubmitEnter } from "@/shared/helpers/funcs";
import useLoading from "@/shared/hooks/useLoading";
import { setToastError } from "@/shared/hooks/useToast";
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  PopoverProps,
  TextField,
  Typography,
} from "@jfds1/jf-design-system";
import { Formik, useFormikContext } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const ProfileConfirmPasswordContent = (popoverProps: PopoverProps & { isLoading: boolean }) => {
  const { values, handleChange, handleSubmit, errors, resetForm } = useFormikContext<{
    password: string;
  }>();

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      handleSubmitEnter(event, handleSubmit);
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  useEffect(() => {
    resetForm();
  }, [popoverProps.open]);

  return (
    <>
      <div className='flex justify-between p-6'>
        <Typography variant='h5'>Xác nhận tài khoản</Typography>
        <Icon
          iconName='close-small'
          variant='outline'
          color='default'
          className='cursor-pointer'
          onClick={popoverProps.onClose}
        />
      </div>
      <ModalBody className='flex max-w-none flex-col items-center p-6'>
        <TextField
          className='w-full'
          name='password'
          onChange={handleChange}
          value={values.password}
          isError={!!errors.password}
          helperText={errors.password}
          placeholder='Placeholder'
          label='Mật khẩu'
          required
          type='password'
          startAdornment={<Icon color='default' iconName='login' />}
        />
      </ModalBody>
      <ModalFooter className='flex justify-end p-6 md:justify-between'>
        <Button
          type='button'
          variant='secondary'
          className='h-12 w-[112px] md:w-full'
          onClick={popoverProps.onClose}
        >
          Hủy
        </Button>
        <Button
          type='button'
          variant='primary'
          className='h-12 w-[112px] md:w-full md:hover:bg-primary'
          onClick={() => handleSubmit()}
          disabled={popoverProps.isLoading}
        >
          Xác nhận
        </Button>
      </ModalFooter>
    </>
  );
};

function ProfileConfirmPasswordModal(props: PopoverProps & { setcurrentTab: any }) {
  const { setcurrentTab, ...resProps } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { userData, setUserData } = useAuthStore();

  const handleGetPrivateInfo = async (password: string) => {
    return await CustomerService.getPrivateInfo(password);
  };

  return (
    <Formik
      initialValues={{ password: "" }}
      onSubmit={(values) => {
        startLoading();
        handleGetPrivateInfo(values.password)
          .then((res: any) => {
            setcurrentTab("security");
            searchParams.set("tabId", "security");
            setSearchParams(searchParams);
            resProps.onClose();
            setUserData({
              ...userData,
              user_profile_info: {
                ...userData?.user_profile_info,
                email: res.data.email,
                phone_number: res.data.phone_number,
              },
              username: res.data.username,
            } as any);
            stopLoading();
          })
          .catch(() => {
            setToastError({
              title: "Mật khẩu không đúng",
            });
            stopLoading();
          });
      }}
    >
      <Modal {...resProps}>
        <ProfileConfirmPasswordContent {...resProps} isLoading={isLoading} />
      </Modal>
    </Formik>
  );
}

export default ProfileConfirmPasswordModal;
