import { API_URL_BUSINESS_USER, API_URL_CUSTOMER, ELYNET_ACCESS_TOKEN } from "@/config/base-url";
import { BusinessUserService } from "@/services/seller.service";
import { CustomerService } from "@/services/customer.service";
import { getCookie, removeCookie, setCookie } from "@/shared/helpers/cookie";
import { CookiesKeys } from "@/shared/types/cookies";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponseHeaders } from "axios";
import { UserRole } from "../zustand/useAuthStore";

export const titleErrorRefreshTokenExpired = `error parsing token: token has invalid claims: token is expired`;
export const FORCE_LOGIN_ERROR = "unauthenticated: invalid token: please login again";

const getRequestHeaders = (additionalHeaders?: any) => {
  const user = getCookie(CookiesKeys.user_info);

  const headers: Record<string, string> = {};

  if (user?.user_info?.id) {
    headers["Authorization"] = "Bearer " + user.token;
    // headers["x-user-id"] = user.user_info.id;
  }

  return { ...headers, ...(additionalHeaders ?? {}) };
};

export interface AxiosResponse<T = never> {
  data: T;
  code: number;
  message: string;
  meta?: {
    total: number;
    page: number;
    page_size: number;
    total_pages: number;
  };
  error?: string;
}

export class CRUDAxios {
  baseURL: string;
  instance: AxiosInstance;
  constructor(baseURL: string) {
    this.baseURL = baseURL;
    const newInstance = axios.create({
      baseURL: this.baseURL,
      headers: {
        "x-access-token": ELYNET_ACCESS_TOKEN,
      },
      timeout: 10000, // 10s
    });
    const role = getCookie(CookiesKeys.user_role);
    newInstance.interceptors.response.use(undefined, async (errorResponse: AxiosError) => {
      if (errorResponse?.response?.status !== 401) return Promise.reject(errorResponse);

      if ((errorResponse.response.data as any).error === FORCE_LOGIN_ERROR) {
        removeCookie(CookiesKeys.user_info);
        removeCookie(CookiesKeys.user_role);
        return Promise.reject(errorResponse);
      }

      const originalRequest = errorResponse.config;

      try {
        const result = await (role === UserRole.customer
          ? CustomerService
          : BusinessUserService
        ).getToken();
        const { token: newToken } = await result.data;
        const cookieUser = getCookie(CookiesKeys.user_info);
        setCookie(CookiesKeys.user_info, {
          ...cookieUser,
          token: newToken,
        });
        const recallResult = newInstance.request({
          ...originalRequest,
          headers: getRequestHeaders({
            ...originalRequest?.headers,
            Authorization: `Bearer ${newToken}`,
          }),
        });
        return recallResult;
      } catch (error) {
        removeCookie(CookiesKeys.user_info);
        removeCookie(CookiesKeys.user_role);
        return Promise.reject(error);
      }
    });

    this.instance = newInstance;
  }

  async getReq<T = any, R = AxiosResponse<T>>(
    path: string,
    option?: AxiosRequestConfig,
  ): Promise<R> {
    const response: AxiosResponse = await this.instance.get(path, {
      ...option,
      headers: getRequestHeaders(option?.headers),
    });
    return response?.data;
  }
  async postReq<T = any, R = AxiosResponse<T>>(
    path: string,
    data: any,
    option?: AxiosRequestConfig,
  ): Promise<R> {
    const response: AxiosResponseHeaders = await this.instance.post(path, data, {
      ...option,
      headers: getRequestHeaders(option?.headers),
    });
    return response.data;
  }
  async putReq<T = never, R = AxiosResponse<T>>(
    path: string,
    data?: any,
    option?: AxiosRequestConfig,
  ): Promise<R> {
    const response: AxiosResponse = await this.instance.put(path, data, {
      ...option,
      headers: getRequestHeaders(option?.headers),
    });
    return response.data;
  }
  async deleteReq<T = never, R = AxiosResponse<T>>(
    path: string,
    option: AxiosRequestConfig,
  ): Promise<R> {
    const response: AxiosResponse = await this.instance.delete(path, {
      ...option,
      headers: getRequestHeaders(option?.headers),
    });
    return response.data;
  }
}

export const CustomerAxios = new CRUDAxios(API_URL_CUSTOMER);

export const BusinessUserAxios = new CRUDAxios(API_URL_BUSINESS_USER);
