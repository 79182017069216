import { TabDataProps } from "@jfds1/jf-design-system";

export const TABS: TabDataProps[] = [
  {
    tabId: "general",
    title: "Thông tin chung",
  },
  {
    tabId: "security",
    title: "Thông tin bảo mật",
  },
];
