import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { CookiesKeys } from "@/shared/types/cookies";

export default function RetrictedAuth({ children }: { children: JSX.Element }) {
  const [cookie] = useCookies([CookiesKeys.user_info]);
  // const [userInfo] = useLocalStorage<UserLocalstorage>("elynet_user_info", {} as UserLocalstorage);

  useEffect(() => {
    if (isEmpty(cookie.user_info)) {
      window.location.href = "/";
    }
  }, [cookie]);

  return children;
}
