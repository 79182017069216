import { REGEX_DATE } from "@/shared/constants/regex";
import { isValidNationalId, isVietnamesePhoneNumber } from "@/shared/helpers/funcs";
import * as YUP from "yup";

export const schemaRegister = YUP.object().shape({
  notsearch_username: YUP.string().required("Vui lòng điền thông tin"),
  notsearch_password: YUP.string()
    .required("Vui lòng điền thông tin")
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Mật khẩu phải có ít nhất 6 ký tự, bao gồm chữ hoa, kí tự đặc biệt, chữ thường và số",
    ),
  first_name: YUP.string().required("Vui lòng điền thông tin"),
  last_name: YUP.string().required("Vui lòng điền thông tin"),
  email: YUP.string()
    .required("Vui lòng điền thông tin")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
  phone_number: YUP.string()
    .required("Vui lòng điền thông tin")
    .test("is-vietnamese-phone-number", "Số điện thoại không hợp lệ", (value) =>
      isVietnamesePhoneNumber(value),
    ),
  brithday: YUP.string(),
  gender: YUP.string(),
  city: YUP.string(),
  district: YUP.string(),
  ward: YUP.string(),
  address: YUP.string(),
  otp: YUP.string().length(6),
});

export const schemaRegisterSeller = YUP.object().shape({
  username: YUP.string().required("Vui lòng điền thông tin"),
  password: YUP.string()
    .required("Vui lòng điền thông tin")
    .matches(
      /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Mật khẩu phải có ít nhất 6 ký tự, bao gồm chữ hoa, kí tự đặc biệt, chữ thường và số",
    ),
  first_name: YUP.string().required("Vui lòng điền thông tin"),
  last_name: YUP.string().required("Vui lòng điền thông tin"),
  email: YUP.string()
    .required("Vui lòng điền thông tin")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
  phone_number: YUP.string()
    .required("Vui lòng điền thông tin")
    .test("is-vietnamese-phone-number", "Số điện thoại không hợp lệ", (value) =>
      isVietnamesePhoneNumber(value),
    ),
  brithday: YUP.string(),
  gender: YUP.string(),
  city: YUP.string(),
  district: YUP.string(),
  ward: YUP.string(),
  address: YUP.string(),
  otp: YUP.string().length(6),
  national_id: YUP.string()
    .required("Vui lòng điền thông tin")
    .test("is-valid-national-id", "Số CMND/CCCD không hợp lệ", (value) => isValidNationalId(value)),
  tax_number: YUP.string().required("Vui lòng điền thông tin"),
});

export const schemaOTP = YUP.object().shape({
  otp: YUP.string().length(6, "OTP tối thiểu 6 chữ số").required("OTP không chính xác"),
});

export const schemaChangePassword = YUP.object().shape({
  username: YUP.string().required("Vui lòng điền thông tin"),
  otp: YUP.string().length(6).required("Vui lòng điền thông tin"),
  newPass: YUP.string().required("Vui lòng điền thông tin"),
  confirmPass: YUP.string().required("Vui lòng điền thông tin"),
});

export const schemaLogin = YUP.object().shape({
  username: YUP.string().required("Vui lòng điền thông tin"),
  password: YUP.string().required("Vui lòng điền thông tin"),
});

export const schemaPrivateProfile = YUP.object().shape({
  username: YUP.string().required("Vui lòng điền thông tin"),
  user_profile_info: YUP.object({
    email: YUP.string()
      .required("Vui lòng điền thông tin")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
    phone_number: YUP.string()
      .required("Vui lòng điền thông tin")
      .test("is-vietnamese-phone-number", "Số điện thoại không hợp lệ", (value) =>
        isVietnamesePhoneNumber(value),
      ),
    // .matches(/^(?:\+?84|0[3|5|7|8|9|2])[0-9]{8,9}$/, "Số điện thoại không hợp lệ"),
  }),
});

export const schemaGeneralProfile = YUP.object().shape({
  user_profile_info: YUP.object({
    first_name: YUP.string().required("Vui lòng điền thông tin"),
    last_name: YUP.string().required("Vui lòng điền thông tin"),
    brithday: YUP.string().matches(REGEX_DATE, "Ngày sinh không hợp lệ"),
    // email: YUP.string().required("Vui lòng điền thông tin"),
    // phone_number: YUP.string().required("Vui lòng điền thông tin"),
    // address_info: YUP.array().of(
    //   YUP.object().shape({
    //     location_detail: YUP.object().shape({
    //       city_province: YUP.string().required("Vui lòng điền thông tin"),
    //       district_town: YUP.string().required("Vui lòng điền thông tin"),
    //       ward_commune: YUP.string().required("Vui lòng điền thông tin"),
    //     }),
    //   }),
    // ),
  }),

  // username: YUP.string(),
  // user_profile_info: YUP.object().shape({
  //   email: YUP.string().required("Vui lòng điền thông tin"),
  //   phone_number: YUP.string().required("Vui lòng điền thông tin"),
  //   birthday: YUP.string().required("Vui lòng điền thông tin"),
  //   avatar: YUP.string().required("Vui lòng điền thông tin"),
  //   address_info: YUP.array().of(
  //     YUP.object().shape({
  //       city: YUP.string().required("Vui lòng điền thông tin"),
  //       district: YUP.string().required("Vui lòng điền thông tin"),
  //       ward: YUP.string().required("Vui lòng điền thông tin"),
  //     }),
  //   ),
  // }),
  // avatar: YUP.string(),
  // first_name: YUP.string().required("Vui lòng điền thông tin"),
  // last_name: YUP.string().required("Vui lòng điền thông tin"),
  // date_of_birth: YUP.string(),
  // gender: YUP.string(),
  // city: YUP.string(),
  // district: YUP.string(),
  // ward: YUP.string(),
  // address: YUP.string(),
  // username: YUP.string().required("Vui lòng điền thông tin"),
  // email: YUP.string().email().required("Vui lòng điền thông tin"),
  // phone_number: YUP.string().required("Vui lòng điền thông tin"),
  // password: YUP.string().required("Vui lòng điền thông tin"),
});

export type SchemaProfileFormContent = typeof schemaGeneralProfile.__outputType;
