import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type CustomerAuthStore = {
  openModalRegister: boolean;
  toggleOpenModalRegister: () => void;
  openModalLogin: boolean;
  toggleOpenModalLogin: () => void;
  openModalChangePassword: boolean;
  toggleOpenModalChangePassword: () => void;
  openModalResetPassword: boolean;
  toggleOpenModalResetPassword: () => void;
  onOpenModalConfirmEmail: boolean;
  toggleOpenModalConfirmEmail: () => void;
};

export const useCustomerAuthStore = create<CustomerAuthStore>()(
  immer((set) => ({
    openModalRegister: false,
    toggleOpenModalRegister: () => {
      set((state) => {
        state.openModalRegister = !state.openModalRegister;
      });
    },
    openModalLogin: false,
    toggleOpenModalLogin: () => {
      set((state) => {
        state.openModalLogin = !state.openModalLogin;
      });
    },
    openModalChangePassword: false,
    toggleOpenModalChangePassword: () => {
      set((state) => {
        state.openModalChangePassword = !state.openModalChangePassword;
      });
    },
    openModalResetPassword: false,
    toggleOpenModalResetPassword: () => {
      set((state) => {
        state.openModalResetPassword = !state.openModalResetPassword;
      });
    },
    onOpenModalConfirmEmail: false,
    toggleOpenModalConfirmEmail: () => {
      set((state) => {
        state.onOpenModalConfirmEmail = !state.onOpenModalConfirmEmail;
      });
    },
  })),
);
